
import clsx from "clsx";
import { FC } from "react";

import styles from "./index.module.scss";
import { Checkbox } from "../checkbox";

interface IProps {
  text: string;
  src: string;
  checked: boolean;
  onClick: () => unknown;
}

export const CheckboxImage: FC<IProps> = ({ text, src, checked, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={clsx(styles.container, {
        [styles.container_checked]: checked,
      })}
    >
      <img className={styles.image} src={src} alt={text} />
      <Checkbox text={text} checked={checked} mix={styles.checkbox} />
    </div>
  );
};
