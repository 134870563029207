
import clsx from "clsx";
import { FC } from "react";

import styles from "./index.module.scss";
import { Container } from "../container";

interface IProps {
  text: string;
  subtitle?: string;
  large?: boolean;
}

export const Title: FC<IProps> = ({ text, subtitle, large }) => {
  return (
    <Container mix={styles.container}>
      <div className={styles.heading}>
        <h3
          className={clsx(styles.title, {
            [styles.title_large]: large,
          })}
        >
          {text}
        </h3>
        {subtitle ? <span className={styles.subtitle}>{subtitle}</span> : null}
      </div>
    </Container>
  );
};
