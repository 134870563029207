import clsx from "clsx";
import React, { ButtonHTMLAttributes, FC } from "react";

import styles from "./index.module.scss";

interface IProps {
  text: string;
  rotateIcon?: number;
  type?: "primary" | "secondary";
  onClick?: () => unknown;
  size?: "medium" | "large";
  iconLeft?: string;
  iconRight?: string;
  disabled?: boolean;
  htmlType?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  mix?: string;
}

export const Button: FC<IProps> = ({
  text,
  onClick,
  iconLeft,
  iconRight,
  disabled,
  size = "medium",
  mix,
  type = "primary",
  rotateIcon = 0,
  htmlType = "button",
}) => {
  return (
    <button
      className={clsx(styles.button, mix, {
        [styles.button_size_large]: size === "large",
        [styles.button_type_secondary]: type === "secondary",
      })}
      onClick={onClick}
      disabled={disabled}
      type={htmlType}
    >
      {iconLeft ? (
        <img
          style={{ transform: `rotate(${rotateIcon}deg)` }}
          height={24}
          width={24}
          className={styles.icon}
          alt="Вход"
          src={`/assets/icons/${iconLeft}`}
        />
      ) : null}
      <span>{text}</span>
      {iconRight ? (
        <img
          height={24}
          width={24}
          className={styles.icon}
          alt="Вход"
          src={`/assets/icons/${iconRight}`}
        />
      ) : null}
    </button>
  );
};
