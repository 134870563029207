
import { FC, Fragment, useEffect, useState } from "react";

import styles from "./index.module.scss";
import { Title } from "../title";
import { CheckboxImage } from "../checkbox-image";
import { Button } from "../button";
import { ISteps } from "../App";
import clsx from "clsx";

interface IStepSex {
  steps: ISteps[]
}

export const StepSex: FC<IStepSex> = ({
  steps
}) => {

  const [step, setStep] = useState(0)


  const stepHandler = (value: number) => {
    setStep((prev) => {
      if (![1, 2].includes(value)) {
        return prev
      }
      if (value === 1) {
        return prev > 0 ? prev - 1 : 0
      } else {
        return prev + 1
      }
    })
  }

  const [male, setMale] = useState(false);
  const [female, setFemale] = useState(false);

  useEffect(() => {
    setMale(false)
    setFemale(false)
  }, [step])


  return (
    <Fragment>
      <Title text={steps[step].title} />

      <div className={styles.items}>
        <CheckboxImage
          onClick={() => setFemale(!female)}
          checked={female}
          text={steps[step].img1Name}
          src="/assets/images/main-landing/sex/female.png"
        />
        <CheckboxImage
          onClick={() => setMale(!male)}
          checked={male}
          text={steps[step].img2Name}
          src="/assets/images/main-landing/sex/male.png"
        />
      </div>

      <div className={clsx(styles.buttons, ((!!steps[step].btn1 && !steps[step].btn2) || (!steps[step].btn1 && !!steps[step].btn2)) && styles.buttons__center)}>
        {!!steps[step].btn1 && (typeof steps[step].btn1 === 'string' ?
          <a href={steps[step].btn1 as string} className={styles.link}>
            <Button
              rotateIcon={180}
              type="secondary"
              iconLeft="arrow.svg"
              size="large"
              text={steps[step].btn1Name}
              mix={styles.button}
            />
          </a> :
          <Button
            rotateIcon={180}
            type="secondary"
            iconLeft="arrow.svg"
            size="large"
            text={steps[step].btn1Name}
            mix={styles.button}
            onClick={() => stepHandler(steps[step].btn1 as number)}
          />)}
        {!!steps[step].btn2 && (typeof steps[step].btn2 === 'string' ?
          <a href={steps[step].btn2 as string} className={styles.link}>
            <Button
              iconRight="arrow.svg"
              size="large"
              text={steps[step].btn2Name}
              mix={styles.button}
            />
          </a> :
          <Button
            iconRight="arrow.svg"
            size="large"
            text={steps[step].btn2Name}
            mix={styles.button}
            onClick={() => stepHandler(steps[step].btn2 as number)}
          />
        )}

      </div>
    </Fragment>
  );
};
