import clsx from "clsx";
import { FC } from "react";

import styles from "./index.module.scss";

interface IProps {
  text: string;
  checked: boolean;
  mix?: string;
}

export const Checkbox: FC<IProps> = ({ checked, text, mix }) => {
  return (
    <div
      className={clsx(styles.checkbox, mix, {
        [styles.checkbox_checked]: checked,
      })}
    >
      <span className={styles.square} />
      <span>{text}</span>
    </div>
  );
};
