import React, { FC, useEffect, useState } from 'react';
import './App.css';
import { StepSex } from './step-sex';
import { Container } from './container';
import styles from "./index.module.scss";

export interface ISteps {
  title: string;
  btn1Name: string;
  btn2Name: string;
  img1Name: string;
  img2Name: string;
  btn1: number | string,
  btn2: number | string
}

const App: FC = () => {
  const [steps, setSteps] = useState<ISteps[]>([]);
  useEffect(() => {
    fetch(`${window.location.pathname === '/' ? '' : window.location.pathname}/steps.json`).then(async el => {
      const data = await el.json()
      setSteps(data)
    })
  }, [])

  if (!steps.length) return null;


  return (
    <>
      <div className={styles.wrapperBlur}>
        <div className={styles.circle_wrapper_1}>
          <img
            className={styles.circle_1}
            src="/assets/landing/bg_blur_1.png"
            alt="bg_blur"
          />
        </div>
        <div className={styles.circle_wrapper_2}>
          <img
            className={styles.circle_2}
            src="/assets/landing/bg_blur_2.png"
            alt="bg_blur"
          />
        </div>
        <div className={styles.circle_wrapper_3}>
          <img
            className={styles.circle_3}
            src="/assets/landing/bg_blur_3.png"
            alt="bg_blur"
          />
        </div>
      </div>
      <main className={styles.main}>
        {!!steps.length && <div className={styles.form}>
          <Container mix={styles.container2}>
            <div className={styles.container}>
              <StepSex steps={steps} />
            </div>
          </Container>
        </div>}
      </main>
    </>
  );
}

export default App;
